import React from "react";
import { Link } from "react-router-dom";
import './Card.css'

import Backgroud1 from './Card_01@2x.png';
import Backgroud4 from './Card_01@2x.png';

const CardPlanes = ({productItems, handleAddProduct}) => {

    return (
 <div>
   <br/><br/>
 <div class="container-fluid" > 
 <div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<h1 class="card-title-planes">Planes y Precios</h1><br/>
<p class="card-text-plan1">Pruebe eMonedero ahora, nuestro software de fidelización se adapta a cualquier tamaño de negocio, trabaje la versión gratis limitada a 20 clientes o si su base de datos es de mayor tamaño reclame la versión "start" o "pro" y pruebe gratis durante un mes sin alguna restricción.</p>
<br/>
<br/>
 <div class="row row-cols-1 row-cols-md-4 g-4">
 <div class="col">
    <div class="plan1 card h-100" >
      <div class="card-body">
        <h5 className="tituloPlan">Libre</h5>
        <h3 className="subtituloPlan"><strong>!Gratis!</strong></h3><br/><br/>
        <p className="card-text-plan">Restringido a registro de 20 clientes, recomendamos optar por la versión start y probar la versión demo de treinta días.</p><br/><br/><br/>
        <button className="product-add-button" ><img src='./img/contratar.png' class="img-responsive-boton4" height='auto' width='100%' alt=""/></button>
      </div>
    </div>
  </div>

 {productItems.map((productItem) => (  
 <div class="col">
    <div class="plan1 card h-100" className={productItem.estilo7}>
      <div class="card-body">
        <h5 className={productItem.estilo1}>{productItem.name}</h5>
        <p className={productItem.estilo2}><label className={productItem.estilo3}><label className={productItem.estilo3}>{productItem.subtitulo}</label><label class="precio">{productItem.price}</label></label><br/><label class="mes">{productItem.subtitulo2}</label></p>
        <p className={productItem.estilo4}>{productItem.texto}</p><br/><br/>
       <button className={productItem.estilo6} onClick={() => handleAddProduct(productItem)}><Link to='/cart'><img src={productItem.imagen} class="img-responsive-boton4" height='auto' width='100%' alt=""/></Link><br/><br/><Link to='/planes'><p className={productItem.estilo5}>{productItem.nota}</p></Link></button>
      </div>
    </div>
  </div>
   ))}

<div class="col">
    <div class="plan1 card h-100" >
      <div class="card-body">
        <h5 className="tituloPlan">Personalizado</h5>
        <h3 className="subtituloPlan"><strong>A tratar</strong></h3><br/><br/>
        <p className="card-text-plan mr">Para empresas con gestión de cartera y sucursales ilimitadas.</p><br/><br/><br/><br/><br/><br/><br/><br/>
        <Link to='/planes'><button className="product-add-button" ><img src='./img/contratar.png' class="img-responsive-boton4" height='auto' width='100%' alt=""/><br/><br/><p className="card-text-plan-line"><Link to='/planes'><label class="prueba">Reclamar prueba.</label></Link></p></button></Link>
      </div>
    </div>
  </div>
</div>

<br/>
<div class="row">
<div class="form-group col-md-5">
</div>
<div class="form-group col-md-4">
<small class="buscas"><Link to='/contacto'><label class="buscas">Contacta con nosotros</label></Link></small>
</div> 
<div class="form-group col-md-4">
</div>  
</div>

</div>
<div class="col-md-1"></div>
</div>

</div><br/><br/>
  </div>
    )
}

export default CardPlanes