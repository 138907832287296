import React from 'react'
import CardBeneficios from '../card/CardBeneficios';
import CardEconomia from '../card/CardEconomia';
import CardEcosistema from '../card/CardEcosistema';
import CardImplementa from "../card/CardImplementa";
import CardIntegracion from '../card/CardIntegracion';
import CardOfrecer from '../card/CardOfrecer';
import CardRecompensas from '../card/CardRecompensas';
import CardSistema from '../card/CardSistema';
import FooterInicio from '../footer/FooterInicio';
import NavbarInicio from '../navbar/NavbarInicio';
import CardPlanesPrecios from '../card/CardPlanesPrecios';
import ContainerInterfaz from '../container/ContainerInterfaz';
const Inicio = ({cartItems, productItems , handleAddProduct}) => {
    return (
        <div>
        <NavbarInicio cartItems={cartItems}/>   
        <CardImplementa/>
        <CardEconomia/>
        <CardEcosistema/>
        <CardSistema/>
        <CardOfrecer/>
        <CardIntegracion/>
        <ContainerInterfaz/>
        <CardBeneficios/>
        <CardRecompensas/>
        <CardPlanesPrecios productItems={productItems} handleAddProduct={handleAddProduct}/>
        <FooterInicio/>  
        </div>
    )
}
export default Inicio;
