import React from 'react'
import { Link } from "react-router-dom";

const CardEcosistema = () => {
    return (
        <div>
<div class="container-fluid" > 
<div class="row">
<div class="col-md-0"></div>
<div class="col-md-10">
<div class="card mb-3 f-sinborde" >
  <div class="row g-0">
    <div class="col-md-6">
    <img src='./img/ecosistema.png' class="card-img img-responsive-implementa"  alt="..."/>
    </div>
    <div class="col-md-6">
      <div class="card-body">
        <h6 class="titulo-ecosistema" >UN VISTAZO AL ECOSISTEMA</h6><br/>
        <h2 class="card-title-monedero">¿Cómo funciona <label class="monedero">eMonedero</label> en tu negocio?</h2><br/>
        <p class="card-text">EMonedero es un ecosistema digital que te ayuda a la fidelización de clientes, el software se apoya de dos sistemas, un panel app que ayuda a facilitar información digital al cliente comprador, eliminando el costo y uso de una tarjeta física, además de facilitar la información sobre sus puntos o descuentos acumulados y el cobro por medio de códigos Qr.</p>
        <p class="card-text">Por parte del usuario administrador ofrecemos un panel web donde se crean y controlan las campañas de beneficios modificando las variables de productos en descuento, porcentaje de retribuciones entre otras opciones.</p><br/>
        <p class="nota">¡No esperes más y empieza a beneficiar tu negocio con las posibilidades que nuestro ecosistema te ofrece!</p><br/>
       <Link to='/configurar'><img src='./img/sector14@2x.png' class="img-responsive-boton2"  height='auto' width='100%'  alt=""/></Link>
      </div>
    </div>
  </div>
 </div> 
</div>
<div class="col-md-1"></div>
</div>  
 </div>     
 <br/><br/> 
 </div>
    )
}
export default CardEcosistema;