import React from 'react'
import { Link } from "react-router-dom";
import Backgroud from './sector20.png';

const CardOfrecer = () => {
    return (
        <div>
<div class="container-fluid  back-ofrecer" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<p class="img-ofrece"><Link to='/'><img src='./img/ofrece-sistema.png'   height='auto' width='25%' alt=""/></Link></p>
  <div class="container-fluid">
  <div class="card mb-3 c-general" >
  <div class="container-fluid"><br/><br/>
  <h6 class="titulo-ofrecer" >LO QUE LE OFRECEMOS</h6>
  <h2 class="subtitulo-ofrecer">¿Qué obtienes con nuestro sistema?</h2><br/>
 
<div class="row">
<div class="col-md-2"></div>
<div class="col-md-8">
<div class="container-fluid">
  <div class="row">
  <div class="row">
  <div class="col-md-6"></div>
  <div class="col-md-2">
  <img src='./img/sector16@2x.png' class="img-center-ofrecer1" height='auto' width='100%' alt=""/> 
  </div>
  <div class="col-md-5"></div>
  </div>
    <div class="col-6 col-sm-6"> <p class="img-economia"><Link to='/'><img src='./img/consumo.png' class="img-center-ofrecer"  height='auto' width='100%' alt=""/></Link></p>
    <h6 class="subtitulos-ofrecer" >Descubre hábitos de consumo</h6>
    <p class="card-text-ofrecer">Conozca los hábitos de consumo de sus clientes y programe beneficios atractivos específicos para cada nicho.</p></div>
    <div class="col-6 col-sm-6"><p class="img-economia"><Link to='/'><img src='./img/soporte.png' class="img-center-ofrecer"  height='auto' width='100%' alt=""/></Link></p>
    <h6 class="subtitulos-ofrecer" >Soporte técnico</h6>
    <p class="card-text-ofrecer">Reciba una excelente atención sobre dudas o problemáticas con el software por parte de los técnicos de desarrollo de nuestro sistema.</p></div>
    <div class="col-6 col-sm-6"><p class="img-economia"><Link to='/'><img src='./img/precios.png' class="img-center-ofrecer" height='auto' width='100%' alt=""/></Link></p>
    <h6 class="subtitulos-ofrecer" >Precios accesibles</h6>
    <p class="card-text-ofrecer">Te ofrecemos precios accesibles para tu negocio, paga el plan que necesitas y empieza a disfrutar de los beneficios.</p></div>
    <div class="col-6 col-sm-6">    <p class="img-economia"><Link to='/'><img src='./img/facilidad.png' class="img-center-ofrecer" height='auto' width='100%' alt=""/></Link></p>
    <h6 class="subtitulos-ofrecer" >Facilidad de los procesos</h6>
    <p class="card-text-ofrecer">El software de eMonedero le permite generar beneficios tanto con clientes que pagan en efectivo como con tarjeta.</p></div>
    <Link to='/planes'><img src='./img/plan.png' class="img-responsive-boton2" height='auto' width='100%' alt=""/></Link>

  </div><br/><br/>
  </div>
  </div>
  <div class="col-md-2"></div>
  </div>
  </div>
  </div>
  </div>
</div>
<div class="col-md-1"></div>
</div>
</div>  
<br/><br/><br/>  
        </div>
    )
}

export default CardOfrecer;
