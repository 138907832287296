import React from 'react'
import './Navbar.css';
import { Link } from 'react-router-dom';

const NavbarInicio = ({ cartItems }) => {
    return (
        <div>
  <nav className="navbar navbar-expand-lg navbar-light" height='auto' width='100%'>
  <div className="container-fluid">
    <Link to=''><img src='./img/sector4@2x.png'  height= '60vw' class="logo-emonedero"  alt=""/> </Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNavDropdown">
      <ul className="navbar-nav mx-auto">
        <li className="nav-item">
          <Link className="nav-link active i-active" aria-current="page" to='/'>Inicio</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link n-inicio" to='/planes'>Planes y precios</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link n-inicio" to='/configurar'>Configurar mi eMonedero</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link n-inicio" to='/contacto'>Contacto</Link>
        </li>
        <li className="nav-item">
        <Link className="nav-link" to='/cart'><i class="cart bi bi-cart3" /><span className="cart-length"> {cartItems.length === 0 ? "": cartItems.length}</span></Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link n-inicio" to='/planes'><img src='./img/sector3@2x.png' class="boton-probar" height='auto' width='100%' alt=""/></Link>
        </li>
      </ul>
    </div>
  </div>
</nav> 
        </div>
    )
}

export default NavbarInicio;