import React from 'react'
import { Link } from "react-router-dom";
import './Card.css';
import Backgroud from './BAckgroundgradiente.jpg';

export const CardBeneficios = () => {
    return (
        <div>
        <div class="container-fluid back-fondo2" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
        
        <div class="row">
        <div class="col-md-11">
       <div class="row">
       <div class="col-md-3"></div>
       <div class="col-md-6">
       <h6 class="titulo-ecosistema">PLANES DE BENEFICIOS</h6><br/>
       <h2 class="card-title-monedero">¿Qué Beneficios Puedes Ofrecer a Tus Clientes?</h2><br/>
       <h6 class="subtitulo-beneficio">eMonedero te permite diversas configuraciones de beneficios para tus clientes, contrata un plan y empieza a explorar las oportunidades de maximizar las ventas de tu negocio. <br/><br/><hr class="lineabeneficio" width="200" /></h6><br/>
       </div>
       <div class="col-md-3"></div>
       </div> 
       
  <div class="cardSinborde card mb-3 f-sinborde fondo c-general">
  <div class="row g-5">
  <div class="col-md-6 ">
  <img src='./img/trupper.png' height='auto' width='100%' alt=""/>
  </div>
  <div class="col-md-6">
  <div class="card-body">
  <div class="cardSinborde card mb-3 f-sinborde fondo c-general">
  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/notificacion.png' class="img-beneficios" height='auto' width='100%'  alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="titulo-beneficio">Notificaciones a clientes</p>
        <p class="card-text">Crea ofertas para productos o redención por puntos y envía notificaciones a tus clientes para incentivar su compra.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/promocion.png' class="img-beneficios" height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="titulo-beneficio">Promociones de temporada</p>
        <p class="card-text">Aprovecha la llegada de fechas o temporadas especiales y crea oportunidades para levantar tus ventas, día del padre, fiestas, días festivos de trabajos entre otros.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/acumulacion.png' class="img-beneficios" height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="titulo-beneficio">Acumulación de puntos</p>
        <p class="card-text">Configura tus formas de bonificación, retribución de puntos por precio de compra, visitas y compras concurrentes a tienda, puntos específicos por producto o compra en línea.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/puntos.png' class="img-beneficios" height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="titulo-beneficio">Puntos especiales</p>
        <p class="card-text">Ve más allá de una lista de clientes y bonifica a tus clientes con un bono por fechas especiales como cumpleaños, aniversarios o navidad.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
  <div class="row g-0">
  <div class="col-md-2">
    <img src='./img/redencion.png' class="img-beneficios" height='auto' width='100%' alt=""/>
    </div>
    <div class="col-md-10">
      <div class="card-body">
        <p class="titulo-beneficio">Redención y canje de puntos</p>
        <p class="card-text">Crea productos con puntos de canje y envía notificaciones a tus clientes con los que pueden canjear sus puntos hasta ahora acumulados.</p>
      </div>
    </div>
    <div class="col-md-4">
    </div>
  </div>
  </div>
  </div>
  <Link to='/planes'><img src='./img/plan.png' class="img-responsive-boton2" height='auto' width='100%' alt=""/></Link>

  </div>
  </div>
</div>
        </div>
        <div class="col-md-1"></div>
        </div>
 </div><br/><br/><br/><br/>
        </div>
    )
}
export default CardBeneficios