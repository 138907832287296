import React from 'react'

const CardSistema = () => {
    return (
        <div>
 <div class="container-fluid"> 
 <div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="card mb-3 f-sinborde" >
  <div class="row g-0">
    <div class="col-md-6">
      <div class="card-body">
        <h6 class="titulo-sistema">DESCARGA NUESTRO SISTEMA</h6><br/>
        <h2 class="card-title-configurar">¿Cómo configurar eMonedero para tu negocio?</h2><br/>
        <p class="card-text">Convierta a sus clientes en consumidores frecuentes de su marca y expanda su mercado con recompensas por referencias. EMonedero es un software que le ayuda a incrementar su cartera de clientes, ventas y el desplazamiento rápido de productos de stock, mediante el desarrollo de programas o campañas.</p>
      </div>
    </div>
    <div class="col-md-6">
    <img src='./img/sistema.png' class="responsive-imagen" height='100%' width='100%' alt="..."/>
    </div>
  </div>
</div> 
</div>
<div class="col-md-1"></div>
</div>
</div> <br/><br/>  
</div>
    )
}

export default CardSistema;