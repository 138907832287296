import React from "react";

const CardEconomia = () => {

    return (
        <div>
<div class="container-fluid" >
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-10">
<div class="card mb-3 f-sinborde" >
<div class="row g-0">
<div class="col-md-6">
<div class="card-body">
<h6 class="titulo-economia" >DESPEGA LA ECONOMÍA DE TU NEGOCIO</h6><br/>
<h2 class="subtitulo-economia">¡Convierte Tus Ventas En <label class="clientes">Clientes Habituales!</label></h2><br/>

  <div class="row">
    <div class="col-6 col-sm-6"><img src='./img/sector7@2x.png' class="img-center" height='auto' width='50%' alt=""/>
    <p class="card-text-economia">Fideliza a tus clientes y llega a nuevos por buenas referencias.</p></div>
    <div class="col-6 col-sm-6"><img src='./img/sector8@2x.png' class="img-center" height='auto' width='50%' alt=""/>
    <p class="card-text-economia">Crea campañas de promociones o descuentos para días específicos.</p></div>
    <div class="col-6 col-sm-6"><img src='./img/sector9@2x.png' class="img-center" height='auto' width='50%' alt=""/>
    <p class="card-text-economia">Aumenta tus ventas creando campañas desde la App.</p></div>
    <div class="col-6 col-sm-6"><img src='./img/sector10@2x.png' class="img-center" height='auto' width='50%' alt=""/>
    <p class="card-text-economia">Fortalece y diferencia tu marca de la competencia.</p></div>
    <div class="col-6 col-sm-6"><img src='./img/sector12@2x.png' class="img-center" height='auto' width='50%' alt=""/>
    <p class="card-text-economia">Aumenta tu cartera de clientes definiendo las variables de recompensa.</p></div>
    <div class="col-6 col-sm-6"><img src='./img/sector11@2x.png' class="img-center" height='auto' width='50%' alt=""/>
    <p class="card-text-economia">Promueve la salida de Stock para productos específicos.</p></div>
    </div>
    </div>
    </div>
    <div class="col-md-6">
    <img src='./img/economia.png'  height='auto' width='100%' alt=""/>
    </div>
  </div>
</div> 
</div>
<div class="col-md-1"></div>
</div>
</div><br/><br/>
    </div>
    )
}

export default CardEconomia