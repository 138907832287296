import React from 'react'
import { Link } from "react-router-dom";

const CardRecompensas = () => {
    return (
        <div>
        <div class="container-fluid"> 
        <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
        <div class="card mb-3 f-sinborde" >
         <div class="row g-0">
           <div class="col-md-6">
             <div class="card-body">
               <h6 class="titulo-sistema">PLANES DE BENEFICIOS</h6><br/>
               <h2 class="card-title-configurar">Implementa Un Plan De Recompensas y Fideliza a Tus Clientes</h2><br/>
               <p class="card-text">Emonedero te permite crear o elegir y modificar estrategias de lealtad de acuerdo a las necesidades de tu negocio, nuestro sistema no restringe las opciones con que se ejecuta determinado beneficio, elige el plan que necesitas y empieza a mejorar tu negocio.</p>
                <Link to='/planes'><img src='./img/sector3@2x.png'  class="img-responsive-boton4" height='auto' width='100%'  alt=""/></Link>

             </div>
           </div>
           <div class="col-md-6">
           <img src='./img/beneficios.png'  height='auto' width='100%' alt="..."/>
           </div>
         </div>
       </div>
        </div>
        <div class="col-md-1"></div>
        </div>
       </div> <br/><br/>
       </div>
    )
}
export default CardRecompensas;