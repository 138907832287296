import React from 'react'
import './Card.css';
import { Link } from "react-router-dom";
import Backgroud from './backgroundImplementa.png';

const CardImplementa = () => {
    return (
        <div>
<div class="container-fluid back-fondo-implementa" style={{background:`url(${Backgroud})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center', backgroundSize:'100% 100%', position:'relative' }}>
<div class="row">
<div class="col-md-1"></div>
<div class="col-md-11">
<div class="card mb-3 f-sinborde fondo c-general" >
  <div class="row g-0">
    <div class="col-md-6">
      <div class="card-body ">
        <br/><br/> <br/><br/>
        <h1 class="card-title-implementa">Implementa, Crece, Fideliza y Vende Más</h1><br/><br/>
        <p class="card-text">eMonedero recompensa a tus clientes con un monedero electrónico único para tu negocio, crea los programas genera las variables de premiación y despega tus ventas promoviendo el retorno de tus clientes y la aceptación de nuevos.</p>
        <br/> <br/>
        <Link to='/planes'><img src='./img/sector2@2x.png' class="mt-2 inline-block img-responsive-boton"  height='auto' width='100%' alt=""/></Link><br/><br/> <br/>  
        <Link to='/'><img src='./img/MICROSIP.png'  height='auto' width='25%' alt=""/></Link><Link to='/'><img src='./img/SYCAR.png'  height='auto' width='25%' alt=""/></Link><br/> 
      </div>
    </div>
    <div class="col-md-6">
    <br/>
    <img src='./img/sector1.png' class="img-responsive-implementa" height='auto' width='100%'  alt=""/>
    </div>
  </div>
</div>
</div>
<div class="col-md-0"></div>
</div>
</div>
<br/><br/><br/>   

        </div>
    )
}

export default CardImplementa;
