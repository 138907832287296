import React from 'react'
import { Link } from "react-router-dom";

const CardIntegracion = () => {
    return (
        <div>
        <div class="container-fluid"> 
        <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
        <div class="card mb-3 f-sinborde" >
         <div class="row g-0">
           <div class="col-md-6">
             <div class="card-body">
               <h6 class="titulo-sistema">INTEGRACIONES</h6><br/>
               <h2 class="card-title-configurar">Conecta Tu Tienda Digital y Mejora el Éxito De Tu Negocio</h2><br/>
               <p class="card-text">eMonedero se conecta perfectamente a tu sistema de punto de venta en tienda física así como a tu eComerce digital. Comience a generar planes de beneficio de acuerdo a los datos de sus clientes y obtenga los resultados que ha deseado.</p>
               <Link to='/planes'><img src='./img/sector3@2x.png'  class="img-responsive-boton3" height='auto' width='100%'  alt=""/></Link>
             </div>
           </div>
           <div class="col-md-6">
           <img src='./img/sector22.png'  class="card-img img-responsive-integracion" alt="..."/>
           </div>
         </div>
       </div>   
       </div> <br/><br/><br/>
        </div>
        <div class="col-md-1"></div>
        </div>
       </div>
    )
}
export default CardIntegracion;